import { LinkItem, Menu, MenuItem, ProductItem } from "./ui/navbar-menu"

import { cn } from "@/utils"
import { useState } from "react"

export default function () {
    return (
        <div className="relative w-full flex items-center justify-center">
            <Navbar className="top-2" />
        </div>
    )
}

function Navbar({ className }: { className?: string }) {
    const [active, setActive] = useState<string | null>(null)
    return (
        <div className={cn("fixed top-10 inset-x-0 max-w-2xl mx-auto z-50", className)}>
            <Menu setActive={setActive}>
                <a href="/">
                    <img src="/logo-small.svg" alt="Wecker" className="w-12" />
                </a>
                <MenuItem setActive={setActive} active={active} item="Solutions">
                    <div className="text-sm grid grid-cols-2 gap-10 p-4">
                        <ProductItem
                            title="Inventory analysis"
                            href="/solutions/selin#inventory"
                            src="http://localhost:4321/_image?href=%2F%40fs%2FUsers%2Fx%2FWork%2Fwecker%2Fwsc%2Fpackages%2Fwebsite%2Fsrc%2Fassets%2Fimages%2Fselin-inventory.png%3ForigWidth%3D2892%26origHeight%3D1714%26origFormat%3Dpng&f=webp"
                            description="Predicts the profitability of your items based on accurate market estimates."
                        />
                        <ProductItem
                            title="Sales insights"
                            href="/solutions/selin#sales"
                            src="http://localhost:4321/_image?href=%2F%40fs%2FUsers%2Fx%2FWork%2Fwecker%2Fwsc%2Fpackages%2Fwebsite%2Fsrc%2Fassets%2Fimages%2Fselin-inventory.png%3ForigWidth%3D2892%26origHeight%3D1714%26origFormat%3Dpng&f=webp"
                            description="Unveils the exact profit made from your sales including all fees, refunds and cost of goods."
                        />
                        <ProductItem
                            title="Product returns tracking"
                            href="/solutions/selin#returns"
                            src="http://localhost:4321/_image?href=%2F%40fs%2FUsers%2Fx%2FWork%2Fwecker%2Fwsc%2Fpackages%2Fwebsite%2Fsrc%2Fassets%2Fimages%2Fselin-inventory.png%3ForigWidth%3D2892%26origHeight%3D1714%26origFormat%3Dpng&f=webp"
                            description="Identifies problematic products, return rate, reason of return, refunds and associated fees."
                        />
                        <ProductItem
                            title="Product competitiveness"
                            href="/solutions/selin#product-fo"
                            src="http://localhost:4321/_image?href=%2F%40fs%2FUsers%2Fx%2FWork%2Fwecker%2Fwsc%2Fpackages%2Fwebsite%2Fsrc%2Fassets%2Fimages%2Fselin-inventory.png%3ForigWidth%3D2892%26origHeight%3D1714%26origFormat%3Dpng&f=webp"
                            description="Flags uncompetitive products and provides a recommended price to beat your competitors."
                        />
                        <ProductItem
                            title="Reseller care"
                            href="/solutions/care#reseller"
                            src="http://localhost:4321/_image?href=%2F%40fs%2FUsers%2Fx%2FWork%2Fwecker%2Fwsc%2Fpackages%2Fwebsite%2Fsrc%2Fassets%2Fimages%2Fselin-inventory.png%3ForigWidth%3D2892%26origHeight%3D1714%26origFormat%3Dpng&f=webp"
                            description="Complete care package for resellers. We take care of your account, monitor your account health, pricing and more."
                        />
                        <ProductItem
                            title="Vendor care"
                            href="/solutions/care#vendor"
                            src="http://localhost:4321/_image?href=%2F%40fs%2FUsers%2Fx%2FWork%2Fwecker%2Fwsc%2Fpackages%2Fwebsite%2Fsrc%2Fassets%2Fimages%2Fselin-inventory.png%3ForigWidth%3D2892%26origHeight%3D1714%26origFormat%3Dpng&f=webp"
                            description="Complete care package for vendors. We take care of your account, monitor your account health, shipments and more."
                        />
                    </div>
                </MenuItem>
                <MenuItem setActive={setActive} active={active} item="Company">
                    <div className="text-sm grid grid-cols-2 gap-10 p-4">
                        <LinkItem
                            title="About Us"
                            href="/about"
                            description="Get to know our company."
                        />
                        <LinkItem
                            title="Contact Us"
                            href="/contact"
                            description="Get in touch."
                        />
                    </div>
                </MenuItem>
                <MenuItem setActive={setActive} active={active} item="Get started">
                    <div className="flex flex-col gap-5 justify-center">
                        <button className="relative inline-flex h-12 overflow-hidden rounded-xl p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                            <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                            <a href="/get-started" className="inline-flex gap-1 whitespace-nowrap h-full w-full cursor-pointer items-center justify-center rounded-xl bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                                Create an account
                            </a>
                        </button>
                        <button className="relative inline-flex h-12 overflow-hidden rounded-xl p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                            <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                            <span className="inline-flex gap-1 whitespace-nowrap h-full w-full cursor-pointer items-center justify-center rounded-xl bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                                Start a free trial
                            </span>
                        </button>
                        <button className="relative inline-flex h-12 overflow-hidden rounded-xl p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                            <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                            <a href="https://selin.wecker.tech/" className="inline-flex gap-1 whitespace-nowrap h-full w-full cursor-pointer items-center justify-center rounded-xl bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                                Try&nbsp;
                                <span className="uppercase font-semibold tracking-wide text-yellow-500">Sel<span className="text-yellow-600">in</span></span>
                            </a>
                        </button>
                    </div>
                </MenuItem>
                <MenuItem setActive={setActive} active={active} item="User">
                    <div className="text-sm grid grid-cols-2 gap-10 p-4">
                        <LinkItem
                            title="Account"
                            href="/user/account"
                            description="Manage your account, recover your password, etc."
                        />
                        <LinkItem
                            title="Subscriptions"
                            href="/user/subscriptions"
                            description="Manage your subscriptions and view subscription status"
                        />
                        <LinkItem
                            title="Billing"
                            href="/user/billing"
                            description="Manage your billing information, view invoices and payment history."
                        />
                        <LinkItem
                            title="Settings"
                            href="/user/settings"
                            description="Manage your settings, notification preferences and similar."
                        />
                    </div>
                </MenuItem>
            </Menu>
        </div>
    )
}
